import React from "react"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import Helmet from "react-helmet"

import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"

const Contact = () => {
  return (
    <>
      <Container>
        <Header>
          <div className="block-header">
            <CustomMenu />
            <TopHeader />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={6}
              ></FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Header>
        <Helmet>
          <title>Contact Page</title>
          <meta name="description" content="Contactez-nous" />
        </Helmet>
        <Content id="page-wrap">
          <div className="bg-contact2">
            <div class="container-contact2">
              <div class="wrap-contact2">
                <section>
                  <form
                    name="bonnesNouvelles"
                    method="post"
                    action="/success"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    className="contact2-form validate-form"
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="bonnesNouvelles"
                    />
                    <span class="contact2-form-title">Contactez-nous</span>
                    <input type="hidden" name="bot-field" />

                    <div className="wrap-input2 validate-input">
                      <input
                        className="input2"
                        type="text"
                        name="name"
                        id="name"
                        required
                      />
                      <span class="focus-input2" data-placeholder="NOM"></span>
                    </div>
                    <div className="wrap-input2 validate-input">
                      <input
                        className="input2"
                        type="email"
                        name="email"
                        id="email"
                        required
                      />
                      <span
                        class="focus-input2"
                        data-placeholder="EMAIL"
                      ></span>
                    </div>
                    <div className="wrap-input2 validate-input">
                      <textarea
                        className="input2"
                        name="message"
                        id="message"
                        rows="4"
                        cols="50"
                        required
                      ></textarea>
                      <span
                        class="focus-input2"
                        data-placeholder="MESSAGE"
                      ></span>
                    </div>
                    <div className="container-contact2-form-btn">
                      <div className="wrap-contact2-form-btn">
                        <ul className="actions">
                          <button
                            type="submit"
                            className="contact2-form-btn"
                            value="Send Message"
                          >
                            Envoyer votre message
                          </button>
                        </ul>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default Contact
